import http from '@/api/http'
/******************测试开始*******************/
export function test(options) {
  return http({
    method: 'post',
    url: '/getHelpList',
    data: { "HelpClassicsId": 0, "Keyword": "", "PageIndex": "1", "PageSize": 20, "Header": { "UserId": "110333", "Auth": "ff19c0aa7aed1c7104754c2085065ff1" } }
  })
}
/*
* 获取机构课程和apps
* */
export function getTokens(options) {
  let url = common.mGetUrl(`${httpUrlStr}/teach/organizations/${common.getOrgId()}/tokens`, options);
  return http({
    method: 'get',
    url: url,
    data: options
  })
}
/******************测试结束*******************/
//获取全部课程列表
export function getCourseAll(data) {
  return http({
    method: 'post',
    url: '/admin/course/getAll',
    data
  })
}

// getAllByType
//获取拥有目标领域课程列表
export function getObjectTypeCourseAll(data) {
  return http({
    method: 'post',
    url: '/admin/course/getAllByType',
    data
  })
}

//获取课程列表
export function getCourseList(data) {
  return http({
    method: 'post',
    url: '/admin/course/getList',
    data
  })
}
//添加课程
export function addCourse(data) {
  return http({
    method: 'post',
    url: '/admin/course/addCourse',
    data
  })
}
//修改课程
export function editCourse(data) {
  return http({
    method: 'post',
    url: '/admin/course/editCourse',
    data
  })
}
//修改课程状态
export function changeCourseState(data) {
  return http({
    method: 'post',
    url: '/admin/course/changeState',
    data
  })
}
//删除课程
export function deleteCourse(data) {
  return http({
    method: 'post',
    url: '/admin/course/deleteCourse',
    data
  })
}
//课程结构定义table表格
export function getAllStructuredefinition(data) {
  return http({
    method: 'post',
    url: '/admin/structuredefinition/getList',
    data
  })
}
//获取课程结构下拉（隶属上级）
export function getStructuredefinitionDrop(data) {
  return http({
    method: 'post',
    url: '/admin/structuredefinition/getAll',
    data
  })
}
//获取标准课程结构下来
export function getStandardlevelDrop(data) {
  return http({
    method: 'post',
    url: '/admin/stdlevel/getAll',
    data
  })
}
//添加课程结构定义
export function addStructureDefinition(data) {
  return http({
    method: 'post',
    url: '/admin/structuredefinition/addStructureDefinition',
    data
  })
}
//修改课程结构定义
export function editStructureDefinition(data) {
  return http({
    method: 'post',
    url: '/admin/structuredefinition/editStructureDefinition',
    data
  })
}
//修改课程结构状态
export function structuredefinitionChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/structuredefinition/changeState',
    data
  })
}
//删除课程结构定义
export function deleteStructureDefinition(data) {
  return http({
    method: 'post',
    url: '/admin/structuredefinition/deleteStructureDefinition',
    data
  })
}
//获取课程(层级)结构列表
export function courseStructureGetList(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/getList',
    data
  })
}
//获取七牛token
export function getQiniuToken(data) {
  return http({
    method: 'post',
    url: '/getQiniuToken',
    data
  })
}
//添加课程结构（层级）
export function addCourseStructure(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/addCourseStructure',
    data
  })
}
//修改课程结构（层级）
export function editCourseStructure(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/editCourseStructure',
    data
  })
}
//修改课程结构（层级）状态
export function structureChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/changeState',
    data
  })
}
//获取资源文件左边的
export function resourcetypeGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/resourcetype/getAll',
    data
  })
}
//获取课程(层级)父级结构列表
export function coursestructureGetParent(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/getParent',
    data
  })
}
//获取全部课程结构列表（资源左侧的树状结构）
export function coursestructureGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/getAll',
    data
  })
}
//获取资源列表
export function courseresourceGetList(data) {
  return http({
    method: 'post',
    url: '/admin/courseresource/getList',
    data
  })
}
//添加资源
export function addCourseResource(data) {
  return http({
    method: 'post',
    url: '/admin/courseresource/addCourseResource',
    data
  })
}
//禁用启用资源
export function courseresourceChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/courseresource/changeState',
    data
  })
}
//删除资源
export function deleteCourseResource(data) {
  return http({
    method: 'post',
    url: '/admin/courseresource/deleteCourseResource',
    data
  })
}
//修改资源（重新上传资源）
export function editCourseResource(data) {
  return http({
    method: 'post',
    url: '/admin/courseresource/editCourseResource',
    data
  })
}
//复制层级（重新上传资源）
export function copyCourseStructure(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/syncCourseStructure',
    data
  })
}
export function deleteCourseStructure(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/deleteCourseStructure',
    data
  })
}
//修改资源是否是上课资源
export function changeClsResource(data) {
  return http({
    method: 'post',
    url: '/admin/courseresource/changeType',
    data
  })
}
//获取课程自己结构列表
export function structureGetChildren(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/getChildren',
    data
  })
}

//修改资源是否正常 禁用
/*
export function changeCourseResourceState(data){
  return http({
    method: 'post',
    url: '/admin/courseresource/changeState',
    data
  })
}
*/

//************************************/
//           业务模块-课程            */
//************************************/

/*************************************/
/***********课程资源类型管理***********/
/*************************************/

/**
 * 获取全部课程资源类型
 * @param data 用户数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getResTypeAll(data) {
  return http({
    method: 'post',
    url: '/admin/resourcetype/getAll',
    data
  })
}

/**
 * 获取课程资源类型列表
 * @param data 课程资源类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getResTypeList(data) {
  return http({
    method: 'post',
    url: '/admin/resourcetype/getList',
    data
  })
}

/**
 * 添加课程资源类型
 * @param data 课程资源类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addResType(data) {
  return http({
    method: 'post',
    url: '/admin/resourcetype/addResourceType',
    data
  })
}

/**
 * 修改课程资源类型
 * @param data 课程资源类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editResType(data) {
  return http({
    method: 'post',
    url: '/admin/resourcetype/editResourceType',
    data
  })
}

/**
 * 修改课程资源类型状态
 * @param data 课程资源类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeResTypeState(data) {
  return http({
    method: 'post',
    url: '/admin/resourcetype/changeState',
    data
  })
}

/**
 * 删除课程资源类型
 * @param data 课程资源类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteResType(data) {
  return http({
    method: 'post',
    url: '/admin/resourcetype/deleteResourceType',
    data
  })
}

/*************************************/
/***********课程文件类型管理***********/
/*************************************/

/**
 * 获取全部课程文件类型
 * @param data 用户数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getFileTypeAll(data) {
  return http({
    method: 'post',
    url: '/admin/filetype/getAll',
    data
  })
}

/**
 * 获取课程文件类型列表
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getFileTypeList(data) {
  return http({
    method: 'post',
    url: '/admin/filetype/getList',
    data
  })
}

/**
 * 添加课程文件类型
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addFileType(data) {
  return http({
    method: 'post',
    url: '/admin/filetype/addFileType',
    data
  })
}

/**
 * 修改课程文件类型
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editFileType(data) {
  return http({
    method: 'post',
    url: '/admin/filetype/editFileType',
    data
  })
}

/**
 * 修改课程文件类型状态
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeFileTypeState(data) {
  return http({
    method: 'post',
    url: '/admin/filetype/changeState',
    data
  })
}

/**
 * 删除课程文件类型
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteFileType(data) {
  return http({
    method: 'post',
    url: '/admin/filetype/deleteFileType',
    data
  })
}
/**
 * 复制课程层级到
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function copyCourseStructureTo(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/syncCourseStructureTo',
    data
  })
}

/**
 * 获取全部帮助主题分类
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function topictypeGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/topictype/getAll',
    data
  })
}
/**
 * 获取帮助主题分类列表
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function topictypeGetList(data) {
  return http({
    method: 'post',
    url: '/admin/topictype/getList',
    data
  })
}
/**
 * 添加帮助分类
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function topictypeAddTopicType(data) {
  return http({
    method: 'post',
    url: '/admin/topictype/addTopicType',
    data
  })
}

/**
 * 修改帮助分类
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function topictypeEditTopicType(data) {
  return http({
    method: 'post',
    url: '/admin/topictype/editTopicType',
    data
  })
}

/**
 * 修改帮助主题状态
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function topictypeChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/topictype/changeState',
    data
  })
}

/**
 * 删除帮助主题状分类
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function topictypeDeleteTopicType(data) {
  return http({
    method: 'post',
    url: '/admin/topictype/deleteTopicType',
    data
  })
}

/**
 * 删除帮助列表
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function helpGetList(data) {
  return http({
    method: 'post',
    url: '/admin/help/getList',
    data
  })
}
/**
 * 添加帮助列表
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function helpAddHelp(data) {
  return http({
    method: 'post',
    url: '/admin/help/addHelp',
    data
  })
}
/**
 * 修改帮助
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function helpEditHelp(data) {
  return http({
    method: 'post',
    url: '/admin/help/editHelp',
    data
  })
}
/**
 * 修改帮助状态
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function helpChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/help/changeState',
    data
  })
}
/**
 * 删除帮助
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function helpDeleteHelp(data) {
  return http({
    method: 'post',
    url: '/admin/help/deleteHelp',
    data
  })
}

/**
 * 获取反馈列表
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function feedbackGetList(data) {
  return http({
    method: 'post',
    url: '/admin/feedback/getList',
    data
  })
}

/**
 * 添加反馈回复
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function feedbackAddReply(data) {
  return http({
    method: 'post',
    url: '/admin/feedback/addReply',
    data
  })
}

/**
 * 删除回复
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function feedbackDeleteReply(data) {
  return http({
    method: 'post',
    url: '/admin/feedback/deleteReply',
    data
  })
}
/**
 * 以百度网盘的形式获取层级和资源
 * */
export function coursestructureGetSubItem(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/getSubItem',
    data
  })
}
//层级重命名
export function renameCourseStructure(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/renameCourseStructure',
    data
  })
}

//获取软件列表
export function getSoftwareList(data) {
  return http({
    method: "post",
    url: "/admin/software/getList",
    data
  })
}

//添加软件版本
export function addSoftware(data) {
  return http({
    method: "post",
    url: "/admin/software/addSoftware",
    data
  })
}

//修改软件版本
export function editSoftware(data) {
  return http({
    method: "post",
    url: "/admin/software/editSoftware",
    data
  })
}

//修改软件状态
export function changeSoftwareState(data) {
  return http({
    method: "post",
    url: "/admin/software/changeState",
    data
  })
}
/**
 * 删除回复
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteResourceCover(data) {
  return http({
    method: 'post',
    url: '/admin/courseresource/deleteResourceCover',
    data
  })
}

/**
 * 获取全部学习栏目分类
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columntypeGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/columntype/getAll',
    data
  })
}
/**
 * 获取学习栏目分类列表
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columntypeGetList(data) {
  return http({
    method: 'post',
    url: '/admin/columntype/getList',
    data
  })
}
/**
 * 添加学习栏目分类
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columntypAddColumnType(data) {
  return http({
    method: 'post',
    url: '/admin/columntype/addColumnType',
    data
  })
}
/**
 * 修改学习栏目分类
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columntypeEditColumnType(data) {
  return http({
    method: 'post',
    url: '/admin/columntype/editColumnType',
    data
  })
}
/**
 * 修改学习主题状态
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columntypeChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/columntype/changeState',
    data
  })
}
/**
 * 删除学习栏目分类
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columntypeDeleteColumnType(data) {
  return http({
    method: 'post',
    url: '/admin/columntype/deleteColumnType',
    data
  })
}


/**
 * 获取全部学习栏目
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columnGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/column/getAll',
    data
  })
}
/**
 * 获取学习栏目列表
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columnGetList(data) {
  return http({
    method: 'post',
    url: '/admin/column/getList',
    data
  })
}
/**
 * 添加学习栏目
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columnAddColumn(data) {
  return http({
    method: 'post',
    url: '/admin/column/addColumn',
    data
  })
}
/**
 * 修改学习栏目
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columnEditColumn(data) {
  return http({
    method: 'post',
    url: '/admin/column/editColumn',
    data
  })
}
/**
 * 修改学习主题状态
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columnChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/column/changeState',
    data
  })
}
/**
 * 删除学习栏目
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columnDeleteColumn(data) {
  return http({
    method: 'post',
    url: '/admin/column/deleteColumn',
    data
  })
}
/**
 * 修改所属学习栏目
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function columnChangeColumn(data) {
  return http({
    method: 'post',
    url: '/admin/column/changeColumn',
    data
  })
}

/**
 * 删除学习栏目图标
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteColumnLogo(data) {
  return http({
    method: 'post',
    url: '/admin/column/deleteColumnLogo',
    data
  })
}
/**
 * 修改学习栏目图标
 * @param data 课程文件类型数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editColumnLogo(data) {
  return http({
    method: 'post',
    url: '/admin/column/editColumnLogo',
    data
  })
}


/**
 * 获取文章列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function articleGetList(data) {
  return http({
    method: 'post',
    url: '/admin/article/getList',
    data
  })
}
/**
 * 添加文章
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function articleAddArticle(data) {
  return http({
    method: 'post',
    url: '/admin/article/addArticle',
    data
  })
}
/**
 * 修改文章
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function articlesEditArticle(data) {
  return http({
    method: 'post',
    url: '/admin/article/editArticle',
    data
  })
}
/**
 * 修改文章状态
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function articleChangeStatearticle(data) {
  return http({
    method: 'post',
    url: '/admin/article/changeState',
    data
  })
}
/**
 * 删除文章
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function articlesDeletearticle(data) {
  return http({
    method: 'post',
    url: '/admin/article/deleteArticle',
    data
  })
}
/**
 * 文章封面cover
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editArticleCover(data) {
  return http({
    method: 'post',
    url: '/admin/article/editArticleCover',
    data
  })
}
/**
 * 复制课程结构(新)
 * 20240108
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function replicateCourseStructure(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/replicateCourseStructure',
    data
  })
}
/**
 * 关联课程结构(新)
 * 20240108
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function relateCourseStructure(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/relateCourseStructure',
    data
  })
}
/**
 * 添加课程类型
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function addStructureType(data) {
  return http({
    method: 'post',
    url: '/admin/structuretype/addStructureType',
    data
  })
}
/**
 * 修改课程类型
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editStructureType(data) {
  return http({
    method: 'post',
    url: '/admin/structuretype/editStructureType',
    data
  })
}
/**
 * 获取课程类型列表
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function structuretypeGetList(data) {
  return http({
    method: 'post',
    url: '/admin/structuretype/getList',
    data
  })
}
/**
 * 修改课程类型状态
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function structuretypeChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/structuretype/changeState',
    data
  })
}
/**
 * 删除课程类型
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteStructureType(data) {
  return http({
    method: 'post',
    url: '/admin/structuretype/deleteStructureType',
    data
  })
}
/**
 * 获取全部课程类型
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function structureTypeGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/structuretype/getAll',
    data
  })
}
/**
 * 获取课程版本列表
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function coursecategoryGetList(data) {
  return http({
    method: 'post',
    url: '/admin/coursecategory/getList',
    data
  })
}
/**
 * 获取全部课程版本
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function coursecategoryGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/coursecategory/getAll',
    data
  })
}
/**
 * 添加课程版本
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function addCourseCategory(data) {
  return http({
    method: 'post',
    url: '/admin/coursecategory/addCourseCategory',
    data
  })
}
/**
 * 修改课程版本
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editCourseCategory(data) {
  return http({
    method: 'post',
    url: '/admin/coursecategory/editCourseCategory',
    data
  })
}
/**
 * 修改课程版本状态
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function coursecategoryChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/coursecategory/changeState',
    data
  })
}
/**
 * 删除课程版本
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteCourseCategory(data) {
  return http({
    method: 'post',
    url: '/admin/coursecategory/deleteCourseCategory',
    data
  })
}
/**
 * 获取课程版本活动列表
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getCategoryActivity(data) {
  return http({
    method: 'post',
    url: '/admin/coursecategory/getCategoryActivity',
    data
  })
}
/**
 * 设置课程版本活动列表
 * 20240201
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function setCategoryActivity(data) {
  return http({
    method: 'post',
    url: '/admin/coursecategory/setCategoryActivity',
    data
  })
}

/**
* 添加目标概览
* */
export function addOverview(data) {
  return http({
    method: 'post',
    url: '/admin/overview/addOverview',
    data
  })
}
/**
 * 获取全部目标概览
 * */
export function overviewGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/overview/getAll',
    data
  })
}
/**
 * 获取目标概览列表
 * */
export function overviewGetList(data) {
  return http({
    method: 'post',
    url: '/admin/overview/getList',
    data
  })
}
/**
 * 修改目标概览
 * */
export function editOverview(data) {
  return http({
    method: 'post',
    url: '/admin/overview/editOverview',
    data
  })
}
/**
 * 修改目标概览状态
 * */
export function overviewChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/overview/changeState',
    data
  })
}
/**
 * 删除目标概览
 * */
export function deleteOverview(data) {
  return http({
    method: 'post',
    url: '/admin/overview/deleteOverview',
    data
  })
}
/**
 * 添加目标模块内容
 * */
export function addModuleContext(data) {
  return http({
    method: 'post',
    url: '/admin/modulecontext/addModuleContext',
    data
  })
}
/**
 * 修改目标模块内容
 * */
export function editModuleContext(data) {
  return http({
    method: 'post',
    url: '/admin/modulecontext/editModuleContext',
    data
  })
}
/**
 * 删除目标模块内容
 * */
export function deleteModuleContext(data) {
  return http({
    method: 'post',
    url: '/admin/modulecontext/deleteModuleContext',
    data
  })
}

/**
 * 获取全部活动分类
 * */
export function activitytypeGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/activitytype/getAll',
    data
  })
}
/**
 * 获取活动分类列表
 * */
export function activitytypeGetList(data) {
  return http({
    method: 'post',
    url: '/admin/activitytype/getList',
    data
  })
}
/**
 * 添加活动分类
 * */
export function addActivityType(data) {
  return http({
    method: 'post',
    url: '/admin/activitytype/addActivityType',
    data
  })
}
/**
 * 修改活动分类
 * */
export function editActivityType(data) {
  return http({
    method: 'post',
    url: '/admin/activitytype/editActivityType',
    data
  })
}
/**
 * 修改活动分类状态
 * */
export function activitytypeChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/activitytype/changeState',
    data
  })
}
/**
 * 删除活动分类
 * */
export function deleteActivityType(data) {
  return http({
    method: 'post',
    url: '/admin/activitytype/deleteActivityType',
    data
  })
}

/**
 * 获取全部标签维度
 * */
export function tagtypeGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/tagtype/getAll',
    data
  })
}
/**
 * 获取标签维度列表
 * */
export function tagtypeGetList(data) {
  return http({
    method: 'post',
    url: '/admin/tagtype/getList',
    data
  })
}
/**
 * 添加标签维度
 * */
export function addTagType(data) {
  return http({
    method: 'post',
    url: '/admin/tagtype/addTagType',
    data
  })
}
/**
 * 修改标签维度
 * */
export function editTagType(data) {
  return http({
    method: 'post',
    url: '/admin/tagtype/editTagType',
    data
  })
}
/**
 * 修改标签维度状态
 * */
export function tagtypeChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/tagtype/changeState',
    data
  })
}
/**
 * 删除标签维度
 * */
export function deleteTagType(data) {
  return http({
    method: 'post',
    url: '/admin/tagtype/deleteTagType',
    data
  })
}

/**
 * 获取标签列表
 * */
export function tagGetList(data) {
  return http({
    method: 'post',
    url: '/admin/tag/getList',
    data
  })
}
/**
 * 添加标签
 * */
export function tagAddTag(data) {
  return http({
    method: 'post',
    url: '/admin/tag/addTag',
    data
  })
}
/**
 * 修改标签
 * */
export function tagEditTag(data) {
  return http({
    method: 'post',
    url: '/admin/tag/editTag',
    data
  })
}
/**
 * 修改标签状态
 * */
export function tagChangeState(data) {
  return http({
    method: 'post',
    url: '/admin/tag/changeState',
    data
  })
}
/**
 * 删除标签
 * */
export function tagDeleteTag(data) {
  return http({
    method: 'post',
    url: '/admin/tag/deleteTag',
    data
  })
}
/**
 * 获取标签相关课程活动
 * */
export function tagGetTagActivity(data) {
  return http({
    method: 'post',
    url: '/admin/tag/getTagActivity',
    data
  })
}
/**
 * 设置标签相关课程活动
 * */
export function tagSetTagActivity(data) {
  return http({
    method: 'post',
    url: '/admin/tag/setTagActivity',
    data
  })
}
/**
 * 获取当前维度下的所有标签
 * */
export function tagGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/tag/getAll',
    data
  })
}
/**
 * 获取课程活动标签
 * */
export function getStructureTags(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/getStructureTags',
    data
  })
}
/**
 * 设置课程活动标签
 * */
export function setStructureTags(data) {
  return http({
    method: 'post',
    url: '/admin/coursestructure/setStructureTags',
    data
  })
}


// 2024-11 开放资源 板块
// 获取板块列表
export function sectorGetList(data) {
  return http({
    method: 'post',
    url: '/admin/section/getList',
    data
  })
}

// 添加板块
export function addSector(data) {
  return http({
    method: 'post',
    url: '/admin/section/addSection',
    data
  })
}

// 修改板块
export function editSector(data) {
  return http({
    method: 'post',
    url: '/admin/section/editSection',
    data
  })
}

// 修改板块状态
export function editSectorStatus(data) {
  return http({
    method: 'post',
    url: '/admin/section/changeState',
    data
  })
}

// 删除板块
export function deleteSector(data) {
  return http({
    method: 'post',
    url: '/admin/section/deleteSection',
    data
  })
}

// 获取全部板块
export function sectorGetAll(data) {
  return http({
    method: 'post',
    url: '/admin/section/getAll',
    data
  })
}
// 获取板块内容列表
export function sectorContentGetList(data) {
  return http({
    method: 'post',
    url: '/admin/sectioncontent/getList',
    data
  })
}

// 添加板块内容
export function addSectorContent(data) {
  return http({
    method: 'post',
    url: '/admin/sectioncontent/addContent',
    data
  })
}

// 修改板块内容
export function editSectorContent(data) {
  return http({
    method: 'post',
    url: '/admin/sectioncontent/editContent',
    data
  })
}

// 修改板块内容状态
export function editSectorContentStatus(data) {
  return http({
    method: 'post',
    url: '/admin/sectioncontent/changeState',
    data
  })
}

// 删除板块内容
export function deleteSectorContent(data) {
  return http({
    method: 'post',
    url: '/admin/sectioncontent/deleteContent',
    data
  })
}

// 修改板块内容置顶状态 ,置顶了封面轮播图必定有
export function editSectorContentSticky(data) {
  return http({
    method: 'post',
    url: '/admin/sectioncontent/editContentSticky',
    data
  })
}

