import http from '@/api/http'
// import qs from 'qs'
export * from '@/api/mApi'
export * from '@/api/zApi'

// 登录相关
// captcha
export function getCaptcha (data) {
  return http({
    method: 'get',
    url: '/captcha',
    data
  })
}
// 登录
export function Login (data) {
  return http({
    method: 'post',
    url: '/admin/login',
    data
  })
}
// 退出登录
export function Logout (data) {
  return http({
    method: 'post',
    url: '/admin/logout',
    data
  })
}

//用户管理接口汇总
//用户列表
export function getUserList (data) {
  return http({
    method: 'post',
    url: '/admin/user/list',
    data
  })
}
//用户角色列表
export function getRole (data) {
  return http({
    method: 'post',
    // url: '/admin/user/role',
    url: '/admin/role/getAll',
    data
  })
}
//添加用户
export function addUser (data) {
  return http({
    method: 'post',
    url: '/admin/user/addUser',
    data
  })
}
//禁用角色
export function editState (data) {
  return http({
    method: 'post',
    url: '/admin/user/editSate',
    data
  })
}
//删除角色
export function delFlag (data) {
  return http({
    method: 'post',
    url: '/admin/user/delFlag',
    data
  })
}
//编辑用户
export function editUser (data) {
  return http({
    method: 'post',
    url: '/admin/user/editUser',
    data
  })
}
//重置密码
export function editPassword (data) {
  return http({
    method: 'post',
    url: '/admin/user/editPassword',
    data
  })
}

//************************************/
//              通用模块              */
//************************************/

/*************************************/
/***************区域管理***************/
/*************************************/

/**
 * 获取全部客户经理
 * @param data 用户数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getManager (data) {
  return http({
    method: 'post',
    url: '/admin/user/manager',
    data
  })
}

/**
 * 获取总部教研员
 * @param data 用户数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getInstructor (data) {
  return http({
    method: 'post',
    url: '/admin/user/instructor',
    data
  })
}

/**
 * 获取全部省级数据
 * @param data 用户数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getProvince (data) {
  return http({
    method: 'post',
    url: '/admin/region/getProvince',
    data
  })
}

/**
 * 获取全部市级数据
 * @param data 省级数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getCity(data){
  return http({
      method: 'post',
      url: '/admin/region/getCity',
      data
  })
}

/**
 * 获取全部区级数据
 * @param data 市级数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getDistrict(data){
  return http({
    method: 'post',
    url: '/admin/region/getDistrict',
    data
  })
}

/**
 * 获取当前节点数据
 * @param data 节点数据(节点Id)
 * @returns jsonData: 成功：1 失败：0
 */
 export function getRegion(data){
  return http({
    method: 'post',
    url: '/admin/region/getRegion',
    data
  })
}

/**
 * 获取上级节点数据
 * @param data 当前节点数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getParent(data){
  return http({
    method: 'post',
    url: '/admin/region/getParent',
    data
  })
}


//************************************/
//           业务模块-客户            */
//************************************/

/*************************************/
/***************机构管理***************/
/*************************************/

/**
 * 获取机构列表
 * @param data 机构数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getOrganizationList (data) {
  return http({
    method: 'post',
    url: '/admin/organization/getList',
    data
  })
}

/**
 * 添加机构
 * @param data 机构数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addOrganization(data){
  return http({
      method: 'post',
      url: '/admin/organization/addOrganization',
      data
  })
}

/**
 * 修改机构
 * @param data 机构数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editOrganization(data){
  return http({
    method: 'post',
    url: '/admin/organization/editOrganization',
    data
  })
}

/**
 * 修改机构状态
 * @param data 机构数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeOrganizationState(data){
  return http({
    method: 'post',
    url: '/admin/organization/changeState',
    data
  })
}

/**
 * 删除机构
 * @param data 机构数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteOrganization(data){
  return http({
    method: 'post',
    url: '/admin/organization/deleteOrganization',
    data
  })
}

/**
 * 分配客户成功经理弹框确定接口
 * @param data 数据
 * @returns jsonData: 成功：1 失败：0
 */
export function setToManager(data){
  return http({
    method: 'post',
    url: '/admin/organization/setToManager',
    data
  })
}

/**
 * 分配教研员弹框确定接口
 * @param data 数据
 * @returns jsonData: 成功：1 失败：0
 */
export function setToInstructor(data){
  return http({
    method: 'post',
    url: '/admin/organization/setToInstructor',
    data
  })
}


/**
 * 导出客户授权列表
 * @param data 数据
 * @returns jsonData: 成功：1 失败：0
 */
export function exportOrgToken(data){
  return http({
    method: 'post',
    url: '/admin/organization/exportOrgToken',
    data
  })
}
/*************************************/
/*************机构成员管理（体验园）*************/
/*************************************/
/**
 * 获取机构成员列表
 * @param data 机构成员数据
 * @returns jsonData: 成功：1 失败：0
 */
export function getExpMemberList (data) {
  return http({
    method: 'post',
    url: '/admin/expuser/getList',
    data
  })
}

export function getUserByMobile (data) {
  return http({
    method: 'post',
    url: '/admin/user/getUser',
    data
  })
}

export function addExpMember(data){
  return http({
    method: 'post',
    url: '/admin/expuser/addExpUser',
    data
  })
}

export function editExpMember(data){
  return http({
    method: 'post',
    url: '/admin/expuser/editExpUser',
    data
  })
}

export function changeExpMemberState(data){
  return http({
    method: 'post',
    url: '/admin/expuser/changeState',
    data
  })
}

export function deleteExpMember(data){
  return http({
    method: 'post',
    url: '/admin/expuser/deleteExpUser',
    data
  })
}
/**
 * 延长体验用户体验时长，每次增加7天
 * @param data 数据
 * @returns jsonData: 成功：1 失败：0
 */
export function extendExpDate(data){
  return http({
    method: 'post',
    url: '/admin/expuser/extendExpDate',
    data
  })
}

/*************************************/
/*************机构成员管理*************/
/*************************************/

/**
 * 获取机构成员列表
 * @param data 机构成员数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getMemberList (data) {
  return http({
    method: 'post',
    url: '/admin/orguser/getList',
    data
  })
 }

/**
 * 添加机构成员
 * @param data 机构成员数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addMember(data){
  return http({
      method: 'post',
      url: '/admin/orguser/addOrgUser',
      data
  })
}

/**
 * 修改机构成员
 * @param data 机构成员数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editMember(data){
  return http({
    method: 'post',
    url: '/admin/orguser/editOrgUser',
    data
  })
}

/**
 * 修改机构成员状态
 * @param data 机构成员数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeMemberState(data){
  return http({
    method: 'post',
    url: '/admin/orguser/changeState',
    data
  })
}

/**
 * 删除机构成员
 * @param data 机构成员数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteMember(data){
  return http({
    method: 'post',
    url: '/admin/orguser/deleteOrgUser',
    data
  })
}

/**
 * 转换机构成员角色，课程负责人-教师身份转换
 * @param data 数据
 * @returns jsonData: 成功：1 失败：0
 */
export function convertOrgUser(data){
  return http({
    method: 'post',
    url: '/admin/orguser/convertOrgUser',
    data
  })
}



/*************************************/
/*************机构授权管理*************/
/*************************************/

/**
 * 获取机构授权列表
 * @param data 机构授权数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getAuthList (data) {
  return http({
    method: 'post',
    url: '/admin/orgtoken/getList',
    data
  })
}

/**
 * 添加机构授权
 * @param data 机构授权数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addAuth(data){
  return http({
      method: 'post',
      url: '/admin/orgtoken/addOrgToken',
      data
  })
}

/**
 * 修改机构授权
 * @param data 机构授权数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editAuth(data){
  return http({
    method: 'post',
    url: '/admin/orgtoken/editOrgToken',
    data
  })
}

/**
 * 修改机构授权状态
 * @param data 机构授权数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeAuthState(data){
  return http({
    method: 'post',
    url: '/admin/orgtoken/changeState',
    data
  })
}

/**
 * 删除机构授权
 * @param data 机构授权数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteAuth(data){
  return http({
    method: 'post',
    url: '/admin/orgtoken/deleteOrgToken',
    data
  })
}

/**
 * 手动发送短信息接口
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function sendMsgAuth(data){
  return http({
    method: 'post',
    url: '/admin/orgtoken/sendSMS',
    data
  })
}

// ======================帐号中心======================
/**
 * 获取用户信息
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getInfo(data){
  return http({
    method: 'post',
    url: '/admin/account/getInfo',
    data
  })
}

//editName
/**
 * 设置用户名
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editName(data){
  return http({
    method: 'post',
    url: '/admin/account/editName',
    data
  })
}

/**
 * 设置性别
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editGender(data){
  return http({
    method: 'post',
    url: '/admin/account/editGender',
    data
  })
}

/**
 * 设置头像
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editAvatar(data){
  return http({
    method: 'post',
    url: '/admin/account/editAvatar',
    data
  })
}

/**
 * 设置邮箱
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editEmail(data){
  return http({
    method: 'post',
    url: '/admin/account/editEmail',
    data
  })
}

/**
 * 设置密码
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editCountPassword(data){
  return http({
    method: 'post',
    url: '/admin/account/editPassword',
    data
  })
}

/**
 * 设置手机
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editMobile(data){
  return http({
    method: 'post',
    url: '/admin/account/editMobile',
    data
  })
}

/**
 * 获取用户操作记录
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getAccountLog(data){
  return http({
    method: 'post',
    url: '/admin/account/getLog',
    data
  })
}




// ======================系统-物料编码管理======================
/**
 * 课程结构-获取课程所有阶段
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getStage(data){
  return http({
    method: 'post',
    url: '/admin/coursestructure/getStage',
    data
  })
}



/**
 * 获取物料编码列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getMaterialList(data){
  return http({
    method: 'post',
    url: '/admin/material/getList',
    data
  })
}

/**
 * 添加物料编码
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function addMaterialCode(data){
  return http({
    method: 'post',
    url: '/admin/material/addMaterialCode',
    data
  })
}

/**
 * 修改物料编码
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editMaterialCode(data){
  return http({
    method: 'post',
    url: '/admin/material/editMaterialCode',
    data
  })
}
/**
 * 更新物料编码状态
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function changeMaterialState(data){
  return http({
    method: 'post',
    url: '/admin/material/changeState',
    data
  })
}
/**
 * 删除物料编码
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteMaterialCode(data){
  return http({
    method: 'post',
    url: '/admin/material/deleteMaterialCode',
    data
  })
}

// ======================业务-机构管理-订单管理======================
/**
 * 获取机构订单列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getOrgOrderList(data){
  return http({
    method: 'post',
    url: '/admin/orgorder/getList',
    data
  })
}

/**
 * 修改机构订单状态
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function changeOrgOrderState(data){
  return http({
    method: 'post',
    url: '/admin/orgorder/changeState',
    data
  })
}

/**
 * 删除机构订单列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteOrgOrder(data){
  return http({
    method: 'post',
    url: '/admin/orgorder/deleteOrgOrder',
    data
  })
}

/**
 * 重新发送短信
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function sendSMSOrgOrder(data){
  return http({
    method: 'post',
    url: '/admin/orgorder/sendSMS',
    data
  })
}


// ======================业务-数据分析-基础数据======================
export function getBasicData(data){
  return http({
    method: 'post',
    url: '/admin/data/getStatData',
    data
  })
}
export function getBasicPositionData(data){
  return http({
    method: 'post',
    url: '/admin/data/getPositionData',
    data
  })
}
export function getBasicSourceData(data){
  return http({
    method: 'post',
    url: '/admin/data/getSourceData',
    data
  })
}
export function getBasicManageData(data){
  return http({
    method: 'post',
    url: '/admin/data/getManagerData',
    data
  })
}

// ======================业务-数据分析-火山数据======================
export function getVolToken(data){
  return http({
    method: 'post',
    url: '/admin/data/getVolToken',
    data
  })
}

/**
 * （已经废弃）
 * 获取用户数据
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getUserData(data){
  return http({
    method: 'post',
    url: '/admin/data/getUserData',
    data
  })
}

/**
 * * （已经废弃）
 * 获取机构数据
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getOrgData(data){
  return http({
    method: 'post',
    url: '/admin/data/getOrgData',
    data
  })
}






// ======================机构管理新增======================
/**
 *
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function setOrgNo(data){
  return http({
    method: 'post',
    url: '/admin/organization/setOrgNo',
    data
  })
}


// ======================首页======================
export function getOrdinaryData(data){
  return http({
    method: 'post',
    url: '/admin/home/getOrdinaryData',
    data
  })
}

export function getHelpIndexList(data){
  return http({
    method: 'post',
    url: '/admin/home/getHelpData',
    data
  })
}

// ======================课程-目标体系-目标领域管理======================
/**
 * 获取全部目标领域
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getTargetareaAllList(data){
  return http({
    method: 'post',
    url: '/admin/targetarea/getAll',
    data
  })
}
/**
 * 获取全部目标领域及子层级
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getTargetareaChildList(data){
  return http({
    method: 'post',
    url: '/admin/targetarea/getChildren',
    data
  })
}

/**
 * 复制目标体系
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function copyTargetareaList(data){
  return http({
    method: 'post',
    url: '/admin/targetarea/replicateObjectStructure',
    data
  })
}

/**
 * 获取目标领域列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getTargetareaList(data){
  return http({
    method: 'post',
    url: '/admin/targetarea/getList',
    data
  })
}
/**
 * 添加目标领域
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function addTargetArea(data){
  return http({
    method: 'post',
    url: '/admin/targetarea/addTargetArea',
    data
  })
}
/**
 * 修改目标领域
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editTargetArea(data){
  return http({
    method: 'post',
    url: '/admin/targetarea/editTargetArea',
    data
  })
}
/**
 * 修改目标领域状态
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function changeTargetareaState(data){
  return http({
    method: 'post',
    url: '/admin/targetarea/changeState',
    data
  })
}
/**
 * 删除目标领域
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteTargetarea(data){
  return http({
    method: 'post',
    url: '/admin/targetarea/deleteTargetArea',
    data
  })
}
// ======================课程-目标体系-关键经验管理======================

/**
 * 获取关键经验列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getKeyconceptList(data){
  return http({
    method: 'post',
    url: '/admin/keyconcept/getList',
    data
  })
}
/**
 * 添加关键经验
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function addKeyconcept(data){
  return http({
    method: 'post',
    url: '/admin/keyconcept/addKeyConcept',
    data
  })
}
/**
 * 修改关键经验
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editKeyconcept(data){
  return http({
    method: 'post',
    url: '/admin/keyconcept/editKeyConcept',
    data
  })
}
/**
 * 修改关键经验状态
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function changeKeyconceptState(data){
  return http({
    method: 'post',
    url: '/admin/keyconcept/changeState',
    data
  })
}
/**
 * 删除关键经验
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteKeyconcept(data){
  return http({
    method: 'post',
    url: '/admin/keyconcept/deleteKeyConcept',
    data
  })
}
// ======================课程-目标体系-教-学路径管理======================

/**
 * 获取关键经验简单列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getKeyExpList(data){
  return http({
    method: 'post',
    url: '/admin/keyconcept/getKeyList',
    data
  })
}
/**
 * 获取教-学路径列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getLearningpathList(data){
  return http({
    method: 'post',
    url: '/admin/learningpath/getList',
    data
  })
}
/**
 * 添加教-学路径
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function addLearningpath(data){
  return http({
    method: 'post',
    url: '/admin/learningpath/addLearningPath',
    data
  })
}
/**
 * 修改教-学路径
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function editLearningpath(data){
  return http({
    method: 'post',
    url: '/admin/learningpath/editLearningPath',
    data
  })
}
/**
 * 修改教-学路径状态
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function changeLearningpathState(data){
  return http({
    method: 'post',
    url: '/admin/learningpath/changeState',
    data
  })
}
/**
 * 删除教-学路径
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteLearningpath(data){
  return http({
    method: 'post',
    url: '/admin/learningpath/deleteLearningPath',
    data
  })
}
